import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/undraw_video_influencer_9oyy (1).svg';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="Computer with video playing" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>Review and iterate</h1>
          <p>
            Review your past videos, learn from the feedback and try again! The more you practice the better you'll get.
          </p>
          <Button href="https://app.videobooth.io/signup">
            Sign Up
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
