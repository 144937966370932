import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import video from 'assets/illustrations/undraw_youtube_tutorial_2gn3.svg';
import { Wrapper, IntroWrapper, Details, Thumbnail } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Practice answering interview questions</h1>
          <h4>Get feedback from your peers</h4>
          <Button href="https://app.videobooth.io/signup">
            Sign Up
          </Button>
        </Details>
        <Thumbnail>
          <img src={video} alt="Computer with video playing and person watching illustration" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
